import "core-js/modules/es.array.push.js";
import { order_page, getSchoolActiveInfo, base_order_check_all } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      dataInfo: [],
      tableData: [{
        id: 5,
        active_name: '西南旅游行',
        yu_order_num: 4,
        settle_order_num: 0,
        yu_money: 1440,
        settle_money: 0,
        settle_time: '',
        settle_state: '待结算'
      }],
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        operator_active_name: '',
        settle_state: '',
        current: 1,
        total: 0
      },
      schoolActive: [],
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.pageInfo.operator_server_active_id = data.id;
      this.getList();
    }
  },
  methods: {
    reset() {
      this.pageInfo.operator_active_name = '';
      this.pageInfo.settle_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      //this.getList();
    },
    getList() {
      order_page(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getSchoolActiveInfo() {
      getSchoolActiveInfo({
        id: this.pageInfo.school_active_id
      }).then(res => {
        this.schoolActive = res.data;
      });
    },
    checkAllOrder() {
      this.$root.ElMessageBox.confirm('你确定要核销该活动的全部订单吗？该操作可能会花费很长时间请耐心等待。', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        base_order_check_all({
          operator_server_active_id: this.pageInfo.operator_server_active_id
        }).then(res => {
          if (res.success) {
            this.$root.ElMessage({
              type: 'success',
              message: res.msg
            });
            this.search();
          } else {
            this.$root.ElMessage.error(res.msg);
          }
        });
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/base/activeApply/activeApplyInfo",
        query: {
          id: id
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};